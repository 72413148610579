import React from 'react'
import Profile from '../components/Profile'
import PrivateRoute from '../components/PrivateRoute'
import Layout from '../components/Layout'
import { navigate } from '@reach/router'

const App = ({data}) => {
  // navigate('/')
  return(
    <Layout>
    <PrivateRoute path="/profile" component={Profile} fsOnly='false' data={data} />
    </Layout>
  )
}

export default App
